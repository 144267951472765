// CONSULT WITH NATHAN BEFORE ADD A NEW COLOR

// Brand Colores
$color-brand-red: #f35369;
$color-brand-yellow: #fcc754;
$color-brand-blue: #54c7ec;

// Primary Colors
$color-blue0: #3593fb;
$color-blue1: #0077ff;
$color-blue2: #3593fc;
$color-blue3: #e5f0ff;
$color-blue4: #f3f8ff;

$color-yellow1: #ffb400;
$color-yellow2: #ffc753;
$color-yellow2: #fff5e1;

$color-red1: #d0081b;
$color-red2: #d7282d;

$color-black0: #1d2129;
$color-black1: #454950;
$color-black2: #777b81;
$color-black3: #a8aaae;
$color-black4: #dddee1;
$color-black5: #e9eaed;
$color-black6: #f6f7f9;
$color-black7: #edeff3;
$color-black8: #707070;
$color-white: #ffffff;

// Secondary Colors
$color-pyellow: #ffc753;
$color-darkblue: #07306c;
$color-mint: #6bcebb;
$color-lavanda: #835eda;
$color-orange: #f7923b;
$color-pink: #da5ed5;
$color-pred: #e9536a;
$color-pblue: #54c7ec;
$color-platano: #a3cd71;
$color-pyellow2: #ffb400;

$color-darkred: #d0011b;
$color-lightred: #fedadc;
$color-positivegreen: #7cd69a;
$color-mintgreen: #88dba5;
$color-green: #1c892f;
$color-shadowblue: #0077ff1a;

// UI Colors
$pageColor: $color-black6;

$color-disabled: #c6c7cc;
$color-borders: $color-black5;
$color-shadows: rgba(#c9c9ce, 0.16);

$color-borders-active: $color-blue1;
$color-shadows-active: rgba($color-blue1, 0.1);

$textSize: 14px;
$textColor: $color-black0;
$textColorInvert: $color-white;

$borderRadius: 4px;

$fontWeights-light: 300;
$fontWeights-regular: 400;
$fontWeights-medium: 500;
$fontWeights-semiBold: 600;
$fontWeights-bold: 700;

@mixin FlexCenterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin FlexVCenter {
  display: flex;
  align-items: center;
}

@mixin interactive {
  cursor: pointer;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

$pageSize: 1230px;
$breakpoint-desktop: 1280px; // change popModalUtils too
$breakpoint-mobile: 700px;
$breakpoint-desktop1920: 1920px; // change popModalUtils too

// Header
$sideBars__desktop: 72px;

// Buttons sizes

$button_height_sm: 28px;
$button_height_md: 40px;
$button_height_lg: 48px;

//Side Menu Updated
$sideMenuBars__desktop: 48px;
$sideMenuWidthExpanded__desktop: 100%;
$sideMenuWidthExpanded__mobile: 90%;

@mixin tablet {
  @media screen and (min-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin desktop1920 {
  @media screen and (min-width: #{$breakpoint-desktop1920}) {
    @content;
  }
}

@mixin sideFilters {
  @media screen and (max-width: 1280px) and (min-width: $breakpoint-desktop) {
    @content;
  }
}
