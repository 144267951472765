@import 'src/style/mixins';

.Container {
  margin-left: 21px;
  display: flex;
  margin-right: 20px;

  @include desktop {
    margin-left: 0;
  }
}

.Brand {
  display: inline-flex;
  align-items: center;

  &.NoHover {
    &:hover {
      cursor: pointer;
    }
  }
}

.Brand:hover,
.Brand:focus {
  text-decoration: none;
}

.BrandNameContainer {
  display: inline-flex;
  overflow: hidden;
  font-size: 14px;
  color: $color-black0;
  transition: all 350ms;
  width: auto;
  max-width: 250px;
  &.hidden {
    max-width: 0;
    display: none;
  }
  &.big {
    font-size: 18px;
  }
}

.BrandName {
  font-weight: $fontWeights-semiBold;
  margin-left: 6px;
}

.BrandSubName {
  font-weight: $fontWeights-light;
  margin-left: 3px;
}
