@import 'src/style/mixins';

.SandboxHeader {
  border-bottom: 1px solid $color-borders;
  background-color: $color-pred;
  width: 100%;
  height: 30px;
  top: 0;
  z-index: 10;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SandboxHeaderEnterprise {
  height: 18px;
  z-index: 10000;
  position: fixed;
}
