@import "./mixins";
@import "./font";

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: $textSize;
}

body {
  font-family: "Poppins", sans-serif;
  color: $textColor;
  margin: 0;
  width: 100%;
  &.modal-active {
    overflow: hidden;
  }
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

/* Remove iOS styles */
input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 16px; // prevent IOS zoom in
}

textarea {
  height: inherit;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  caret-color: $color-blue1;
  padding: 13px 20px;
  height: 45px;
  border: 1px solid $color-borders;
  border-radius: $borderRadius;
  transition: border ease-in 205ms;
  &::placeholder {
    color: $color-black0;
    font-style: italic;
    font-weight: lighter;
  }
  &:focus {
    border-color: $color-borders-active;
    outline: none;
  }
  @include tablet {
    &:hover {
      border-color: $color-borders-active;
    }
  }
}

button:focus,
canvas:focus,
textarea:focus,
input:focus {
  outline: none;
}

label {
  font-weight: 600;
}

h1 {
  font-size: 32px;
  font-weight: $fontWeights-semiBold;
}

h2 {
  font-size: 24px;
  font-weight: $fontWeights-light;
}

h3 {
  font-size: 18px;
  font-weight: $fontWeights-bold;
}

h4 {
  font-size: 16px;
  font-weight: $fontWeights-medium;
}

// link Styles
a {
  color: $color-blue2;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  @include tablet {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.hideInlineInMobile {
  display: none;
  @include tablet {
    display: inline;
  }
}

// ///////////////////////////////////////////////
// depricate
.error {
  color: $color-pred !important;
  border-color: $color-pred !important;
}

.hr-text {
  line-height: 1em;
  margin: 12px 0;
  position: relative;
  outline: 0;
  border: 0;
  color: $color-black0;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $color-black0;
    padding: 0 0.5em;
    line-height: 1.5em;
    background-color: $color-white;
  }
}

.GlobalSubtext {
  color: $color-black2;
}

.MarginBottom24 {
  margin-bottom: 24px !important;
}

.AlertBox {
  background: $color-pred;
  border-radius: $borderRadius;
  color: $color-white;
  padding: 15px 8px;
  text-align: center;
  &.Success {
    background-color: $color-platano;
  }
}

.LayoutContainerCenter {
  padding: 0 24px;
  margin: 0 auto;
  max-width: $pageSize;
}

.TagRed,
.TagGreen {
  padding: 4px 8px 2px;
  border-radius: 2px;
  color: $color-white;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
}

.TagRed {
  background-color: $color-pred;
}
.TagGreen {
  background-color: $color-platano;
}

// chevron
$chevronSize: 10px;

.chevron-icon {
  width: 12px; // hypotenuse
  &::after {
    content: "";
    display: inline-block;
    width: $chevronSize;
    height: $chevronSize;
    border-style: solid;
    border-width: 1px 1px 0 0;
    transform: rotate(135deg) translate(-1.5px, 1.5px);
    transition: transform 0.2s ease-in-out;
  }
}

.chevron-icon.active::after {
  transform: rotate(-45deg) translate(-1.5px, 1.5px);
}

.chevron-icon.chevron-back::after {
  transform: rotate(-135deg) translate(-1.5px, 1.5px);
}

.chevron-icon.chevron-next::after {
  transform: rotate(45deg) translate(-1.5px, 1.5px);
}

// if PWA open
@media all and (display-mode: standalone) {
}

// React Modal styles
.ReactModal__Overlay {
  // opacity: 0;
  :local(.Modal) {
    transform: scale(0.96);
    transition: all 300ms cubic-bezier(0.48, 0.15, 0.25, 0.96);
  }
  // transition: all 300ms cubic-bezier(0.48, 0.15, 0.25, 0.96);
}

.ReactModal__Overlay--after-open {
  // opacity: 1;
  :local(.Modal) {
    transform: scale(1);
  }
}

.ReactModal__Overlay--before-close {
  // opacity: 0;
  :local(.Modal) {
    transform: scale(0.96);
  }
}

///Browser Checker

.SupportModal_Container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 30;
  overflow: hidden !important;
}

.BrowserChecker_Message {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  position: absolute;
  min-width: 400px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: hidden !important;

  & > div {
    display: flex;
    justify-content: center;
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.BrowserChecker_Label {
  font-size: 18px;
  font-weight: $fontWeights-semiBold;
}

.BrowserChecker_Text {
  color: $color-black2;
  font-size: 14px;
  font-weight: $fontWeights-light;
  max-width: 346px;
  text-align: center;
}

.BrowserChecker_ButtonContainer {
  margin-top: 20px;
  margin: 0 5px;
  button,
  a {
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0 none;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.3s ease-out;
    background-color: $color-blue3;
    border-radius: $borderRadius;
    border: 1px solid $color-blue3;
    height: 36px;
    padding: 0 12px;
    color: $color-blue2;
    font-size: 12px;
    font-weight: $fontWeights-semiBold;
    &:focus {
      outline: 0 none;
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      background: $color-black5 !important;
      cursor: default !important;
    }

    @include tablet {
      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        text-decoration: none;
      }
    }
  }
}

.BrowserChecker_Hide {
  display: none;
}

.shepherd-custom {
  &-highlight {
    border: 1px solid $color-blue1;
    &-padding {
      padding: 5px;
      border: 1px solid $color-blue1;
    }
    &-rounded {
      border: 1px solid $color-blue1 !important;
      border-radius: 4px !important;
    }
  }
  &-highlight-no-overlay {
    border: 3px solid $color-blue1 !important;
    border-radius: 4px !important;
  }
  &-step {
    padding: 10px;
  }
  &-mt {
    transform: translateY(5px);
  }
  &-mb {
    transform: translateY(-5px);
  }
  &-ml {
    transform: translateX(5px);
  }
  &-mr {
    transform: translateX(-5px);
  }
  &-button {
    &-skip {
      padding: 0 !important;
      background: transparent !important;
      color: $color-blue1 !important;
      &-text {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  &-progress-bar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 4px;
    margin-bottom: 24px;
    .step {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-block;
    }
    .past {
      background-color: $color-blue3;
    }
    .current {
      background-color: $color-blue2;
    }
    .upcoming {
      border: 1px solid $color-black3;
    }
  }
}

.shepherd-text {
  padding: 0 !important;
}

.shepherd-footer {
  padding: 0 !important;
}

.shepherd-element {
  padding: 24px !important;
}