@font-face {
  font-family: "poppins";
  src: url("/fonts/poppins-light-webfont.woff2") format("woff2"),
    url("/fonts/poppins-light-webfont.woff") format("woff"),
    url("/fonts/poppins-light-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("/fonts/poppins-regular-webfont.woff2")
      format("woff2"),
    url("/fonts/poppins-regular-webfont.woff") format("woff"),
    url("/fonts/poppins-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("/fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("/fonts/poppins-medium-webfont.woff") format("woff"),
    url("/fonts/poppins-medium-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("/fonts/poppins-semibold-webfont.woff2")
      format("woff2"),
    url("/fonts/poppins-semibold-webfont.woff") format("woff"),
    url("/fonts/poppins-semibold-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  src: url("/fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("/fonts/poppins-bold-webfont.woff") format("woff"),
    url("/fonts/poppins-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
