@import 'src/style/mixins';

// Boxes
.LoadingLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  border-radius: $borderRadius;
  animation: FadeIn 250ms ease-in 0ms 1 normal forwards running;
  @include FlexCenterCenter;
  &.Fixed {
    position: fixed;
    z-index: 30;
  }

  &.WhiteTheme {
    background: rgba($color-white, 0.5);
  }

  &.BlackTheme {
    background: rgba($color-black1, 0.5);
  }

  &.NoneTheme {
    background: none;
  }
}

@keyframes FadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.LogoContainer {
  display: grid;
  justify-items: center;
  grid-gap: 4px;
  font-size: 14px;
  color: $color-black3;
}
