@import "src/style/mixins";

@import "src/style/mixins";

.Container {
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-size: 257% 67%;
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
  background-image: url("/images/backgrounds/404-bg.svg");

  @include tablet {
    background-size: 180% 58%;
  }

  @include desktop {
    background-size: 102% 60%;
  }
}

.Logo {
  position: absolute;
  width: 29px;
  height: 26.04px;
  top: 17.8px;
  left: 20px;
  @include tablet {
    display: none;
  }
}

.LogoDesktop {
  display: none;
  @include tablet {
    display: inline;
    position: absolute;
    width: 245px;
    height: 60px;
    top: 19px;
    left: 27px;
  }

  @include desktop {
    top: 28px;
    left: 43px;
  }
}

.Title {
  font-size: 20px;
  color: $color-black3;
  margin-bottom: 17.5px;
  font-weight: $fontWeights-bold;
  @include tablet {
    margin-bottom: 29.5px;
  }

  @include tablet {
    margin-bottom: 41.5px;
  }
}

.Image {
  width: 187px;
  height: 124px;
  margin-bottom: 8px;
  @include tablet {
    width: 241px;
    height: 160px;
    margin-bottom: 18px;
  }

  @include desktop {
    margin-bottom: 27px;
  }
}

.MainText {
  font-size: 20px;
  max-width: 800px;
  padding: 0;
  margin: 0;
  font-weight: $fontWeights-bold;
  @include tablet {
    font-size: 26px;
    margin-bottom: 8px;
    > br {
      display: none;
    }
  }

  @include desktop {
    margin-bottom: 12px;
  }
}


.Contact {
  text-decoration: underline;
  color: inherit;
  font-weight: $fontWeights-light;
  margin-top: 20px;
  @include desktop {
    font-size: 17px;
  }
}

.Maintenance {
  text-decoration: underline;
}