@import 'src/style/mixins';

.LoadingDoors {
  font-size: 15px;

  &.breakLine {
    display: flex;
    justify-content: center;
    margin: 88px 0 54px;
  }

  &:hover {
    text-decoration: none;
  }

  .doors {
    display: flex;
    height: 1.78571em;
  &.center {
      justify-content: center;
    }
  }

  .door {
    position: relative;
    border-radius: 0.03571em;

    &.xs {
      width: 0.439561em;
      height: 0.82417em;
    }

    &.s {
      width: 0.57143em;
      height: 1.07143em;
    }

    &.m {
      width: 0.742859em;
      height: 1.392859em;
    }

    &.l {
      width: 0.9657167em;
      height: 1.392859em;
    }


    &::before {
      content: "";
      display: block;
      position: absolute;
      background-color: $color-white;
      width: 0.07214em;
      height: 0.07214em;
      border-radius: 50%;
      bottom: 50%;
      left: 0.07143em;
    }
  }

  .s_red {
    background-color: $color-brand-red;
    margin-right: 0.14286em;
    top: 0.35714em;
  }

  .s_yellow {
    background-color:$color-brand-yellow;
    margin-right: 2px;
    top: 0.07143em;
  }

  .s_blue {
    background-color: $color-brand-blue;
    top: 0.57143em;
  }

  .brand {
    margin-left: 0.42857em;
    font-size: 1.07143em;
    font-weight: $fontWeights-bold;
    color: $color-black5;
    vertical-align: middle;
  }

  &.gray {
    .door {
      background-color: $color-black4;
    }
  }

  &.animated {
    .door {
      animation-duration: 2s;
      animation-iteration-count: 2;
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.loop {
      .door {
        animation-iteration-count: infinite;
      }
    }

    .s_red {
      animation-name: door-bounce1;
    }

    .s_yellow {
      animation-name: door-bounce2;
    }

    .s_blue {
      animation-name: door-bounce3;
    }
  }
}

// door animation
@keyframes door-bounce1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.2em);
  }
}

@keyframes door-bounce2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.5em);
  }
}

@keyframes door-bounce3 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.4em);
  }
}
